import { create } from "zustand";

interface IVideoQueueState {
  videoQueue: string[];
  addToQueue: (value: string) => void;
  removeFromQueue: (value: string) => void;
  getLastVisibleVideo: () => string | undefined;
}

export const useVideoQueueStore = create<IVideoQueueState>((set, get) => ({
  videoQueue: [],
  addToQueue: (value) => set((state) => ({ videoQueue: state.videoQueue.includes(value) ? state.videoQueue : [...state.videoQueue, value] })) ,
  removeFromQueue: (value) => set((state) => ({ videoQueue: state.videoQueue.filter((id) => id !== value) })),
  getLastVisibleVideo: () => {
    const { videoQueue } = get();
    return videoQueue[videoQueue.length - 1];
  },
}));
