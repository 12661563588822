import { apiMain } from "@/common/services/index";
import { Experiment, ExperimentClient, Variants } from "@amplitude/experiment-js-client";
import { loadExperiments, loadFirstPageUrl } from "@/common/utils/storage";
import { bus } from "@/common/buses/metrics/bus";
import { IExperimentList } from "@/common/services/types";
import { isTrackingEnabled } from "@/common/utils/tracking";
import sessionService from "@/common/services/session/sessionService";
import { getDeviceType, getWindowWidth } from "@/common/utils/device";
import { isCookieDeclined } from "@/common/utils/cookie";

interface IVariants {
  [key: string]: string | boolean | null;
}

const isProduction = import.meta.env.MODE === "production";
const API_KEY = import.meta.env.REACT_APP_AMPLITUDE_KEY;

class ExperimentService {
  private variants: null | IVariants = null;
  private exposures: string[] = [];
  private fetchPromise: Promise<[IExperimentList, Variants]> | null = null;
  private getClientPromise: Promise<ExperimentClient> | null = null;

  async fetchVariants(force: boolean = false) {
    if (force) {
      await this.clearVariants();
    }

    if (this.variants !== null) {
      return this.variants;
    }

    try {
      if (this.fetchPromise === null) {
        this.fetchPromise = this.createFetchPromise();
      }

      const [internalVariants, remoteVariants] = await this.fetchPromise;

      this.fillVariants(internalVariants, remoteVariants);
    } catch (e) {
      console.error(e);
    } finally {
      this.fetchPromise = null;
    }
  }

  async getVariant(experimentKey: string, fallback: string | boolean, coverage: number = 1) {
    if (isCookieDeclined()) {
      return fallback;
    }

    const experimentEnabled = loadExperiments() !== null || await isTrackingEnabled(coverage);
    if (!experimentEnabled) {
      return fallback;
    }

    const variants = await this.getAllVariants();
    const variant = variants[experimentKey] ?? null;

    if (variant !== null) {
      if (!this.exposures.includes(experimentKey)) {
        bus.emit("experimentVariantExposure", {
          experimentKey,
          experimentVariant: variant
        });

        const client = await this.getClient();
        client.exposure(experimentKey);

        this.exposures.push(experimentKey);
      }

      return variant;
    }

    return fallback;
  }

  async getAllVariants(): Promise<IVariants> {
    if (this.variants === null) {
      await this.fetchVariants();
    }

    const localVariants = loadExperiments<IVariants>() ?? {};
    const fetchedVariants = this.variants ?? {};

    return {
      ...fetchedVariants,
      ...localVariants
    };
  }

  async clearVariants() {
    const client = await this.getClient();
    client.clear();
    this.variants = null;
    this.exposures = [];
  }

  async getClient(): Promise<ExperimentClient> {
    if (!this.getClientPromise) {
      this.getClientPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            Experiment.initializeWithAmplitudeAnalytics(API_KEY, {
              debug: !isProduction,
              fetchOnStart: false,
              pollOnStart: false,
              fetchTimeoutMillis: 200,
              retryFetchOnFailure: false
            })
          );
        }, 0);
      });
    }

    return await this.getClientPromise;
  }

  private fillVariants(internalVariants: IVariants, remoteVariants: Variants) {
    this.variants = {};

    for (const key in internalVariants) {
      this.variants[key] = internalVariants[key];
    }

    for (const key in remoteVariants) {
      this.variants[key] = remoteVariants[key].value ?? null;
    }
  }

  private async createFetchPromise(): Promise<[IExperimentList, Variants]> {
    const client = await this.getClient();
    await client.start().catch((reason) => {
      console.debug(reason);
    });

    const deviceId = (await sessionService.getSession()).session;
    const firstPageURL = loadFirstPageUrl() ?? window.location.pathname;
    const landedToWatchPage = firstPageURL.startsWith("/watch/");

    return await Promise.all([
      apiMain.getExperiments(),
      client.fetch({
        device_id: deviceId,
        user_properties: {
          "Device Type": getDeviceType(getWindowWidth()),
          "Landed to Watch Page": landedToWatchPage
        }
      }).then(client => client.all())
    ]);
  }
}

export default new ExperimentService();
