/* eslint-disable import/no-unused-modules */
import recombee, { ApiClient, Request } from "recombee-js-api-client";
import { useUserStore } from "@/common/store/userStore";
import { useRecommendedFeedStore } from "@/common/store/recommendedFeedStore";
import { isIframe } from "@/common/utils/detectIframe";
import { isCookieDeclined } from "@/common/utils/cookie";

class RecombeeClient {
  private client: ApiClient;

  constructor() {
    const databaseId = import.meta.env.REACT_APP_RECOMBEE_DATABASE_ID;
    const token = import.meta.env.REACT_APP_RECOMBEE_TOKEN;

    this.client = new ApiClient(databaseId, token, { region: "us-west" });
  }

  sendDetailView(itemId: string) {
    return this.send(new recombee.AddDetailView(this.getUserId(), itemId, { "recommId": this.getRecommendationId(itemId) }));
  }

  sendPortionView(itemId: string, portion: number) {
    if (!portion) {
      return;
    }

    return this.send(new recombee.SetViewPortion(this.getUserId(), itemId, portion, { "recommId": this.getRecommendationId(itemId) }))
      .catch((e) => {
        console.error(`Error sending view portion: ${e}`);
      });
  }

  sendAddBookmark(itemId: string) {
    return this.send(new recombee.AddBookmark(this.getUserId(), itemId, { "recommId": this.getRecommendationId(itemId) }));
  }

  sendAddRating(itemId: string, rating: number) {
    return this.send(new recombee.AddRating(this.getUserId(), itemId, rating, { "recommId": this.getRecommendationId(itemId) }));
  }

  private getRecommendationId(gifId: string) {
    const { feedGifsIds, feedId } = useRecommendedFeedStore.getState();
    return feedGifsIds.includes(gifId) ? feedId : "";
  }

  private getUserId() {
    const user = useUserStore.getState().currentUser;
    if (user?.username) {
      return user.username;
    }

    return useUserStore.getState().sessionId ?? "";
  }

  private async send(request: Request) {
    if (isCookieDeclined()) {
      return;
    }

    if (!this.getUserId() || isIframe()) {
      return;
    }

    try {
      return await this.client.send(request);
    } catch (e) {
      console.debug(e);
    }
  }
}

export default new RecombeeClient();
