import { useAppStore } from "@/common/store/appStore";
import { useEffect, useRef, useCallback } from "react";

interface IUseHeaderScrollProps {
  wrapperRef: React.RefObject<HTMLElement>;
}

const SCROLL_THRESHOLD = 10;

export const useHeaderScroll = ({ wrapperRef }: IUseHeaderScrollProps) => {
  const prevScrollY = useRef(0);
  const isSearchBarOpen = useAppStore((state) => state.isSearchBarOpen);

  const handleScroll = useCallback(() => {
    const currentScrollY = wrapperRef?.current?.scrollTop ?? 0;
    const prevScroll = prevScrollY.current < 0 ? 0 : prevScrollY.current;
    const scrollDifference = Math.abs(currentScrollY - prevScroll);

    if (scrollDifference <= SCROLL_THRESHOLD) {
      return;
    }

    const isScrolledDown = currentScrollY > prevScroll;
    prevScrollY.current = currentScrollY;

    const element = document.querySelector("#root");

    if (element === null) {
      return;
    }

    isScrolledDown ? element.classList.add("scrolled") : element.classList.remove("scrolled");
  }, [wrapperRef]);

  useEffect(() => {
    const currentWrapper = wrapperRef.current;
    if (!currentWrapper || isSearchBarOpen) {
      return;
    }

    currentWrapper.addEventListener("scroll", handleScroll);
    return () => {
      currentWrapper.removeEventListener("scroll", handleScroll);
    };
  }, [wrapperRef, handleScroll, isSearchBarOpen]);
};
